import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';



// tslint:disable-next-line:import-spacing
import{
  HomeComponent,
  ProjectsComponent,
  CvComponent,
  ContactComponent,
  ProjectProfilComponent
} from './index';



const routes: Routes =[
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: 'home',             component: HomeComponent },


    {path: 'contact', component: ContactComponent},
    {path: 'projects', component : ProjectsComponent},
    {path: 'cv', component: CvComponent},
    {path: 'projects/:name', component: ProjectProfilComponent}
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes,{
      useHash: true
    })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
