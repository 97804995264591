// tslint:disable-next-line:eofline
import { Projet } from '../../model/projet.model';
import { Skill } from '../../model/skill.model';
import { Formation } from '../../model/formation.model'
import { Professionnal } from '../../model/professional.model';
import projets from '../../data/projects.json';
import skills from '../../data/skills.json';
import formations from '../../data/formations.json';
import professional from '../../data/professional.json';

export class BuildData {
    constructor() {}

   getProjectsList(): Projet[] {
     const allProjet: Projet[] = <Projet[]> JSON.parse(JSON.stringify(projets));
      return allProjet;
   }

   getSkillList(): Skill[]{
     const allSkill: Skill[] = <Skill[]> JSON.parse(JSON.stringify(skills));
     return allSkill;
   }

   getFormationList(): Formation[]{
    const allFormation: Formation[] = <Formation[]> JSON.parse(JSON.stringify(formations));
    return allFormation;
   }

   getAllProfessional(): Professionnal[] {
    const professionals: Professionnal[] = <Professionnal[]> JSON.parse(JSON.stringify(professional));
    return professionals;
   }

   getProjectByName(name: string): Projet {
     return this.getProjectsList().find(x => x.name === name);
   }

}