import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Projet } from 'app/model/projet.model';
import { BuildData } from 'app/shared/helper/buildData.helper';

@Component({
  selector: 'app-project-profil',
  templateUrl: './project-profil.component.html',
  styleUrls: ['./project-profil.component.css']
})
export class ProjectProfilComponent implements OnInit {
  projet: Projet;
  technologies: string[] = [];
  isGoodProject: boolean;
  haveSourceFile: boolean;
  
  constructor(private readonly buildprojects: BuildData, private readonly route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.params
            .subscribe( async params =>{
              const _name = params['name'];
              this.projet = this.buildprojects.getProjectByName(_name);
              this.isGoodProject =  this.projet !== undefined ;
            });
    this.technologies = this.projet.programingLangage.split(';');
    this.haveSourceFile = this.projet.source_file.length !== 0;
  }


}
