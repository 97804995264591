import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BuildData } from '../../shared/helper/buildData.helper';
import { Projet } from '../../model/projet.model';
import { Router } from '@angular/router';
import { stringify } from 'querystring';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css']
})
export class ProjectsComponent implements OnInit {
  projets: Projet[] = [];
  // tslint:disable-next-line: no-shadowed-variable
  private maxCharacter: number = 70;
  constructor(private readonly buildprojects: BuildData, private readonly router: Router, public translate: TranslateService) { }

  ngOnInit(): void {
    this.projets = this.buildprojects.getProjectsList();
  }

  private redirectTo(url: string, param: string = ''): void {
    setTimeout(() => {
        // tslint:disable-next-line: prefer-const
        let route = this.router.url.split('/');
        this.router.navigateByUrl(`/${route[1]}/${url}`);
    }, 0)
  }

  projectRedirection(name: string) {
      this.redirectTo(name);
  }

  // tslint:disable-next-line:member-ordering
  projectDecription(key: string): string {
    var value : string;
    this.translate.get(key).forEach( val => value = val);
    value += '';
    let sliceValue = this.maxCharacter;
    if (value.length >= this.maxCharacter) {
      if (value[this.maxCharacter] != ' ') {
          for (let i  = this.maxCharacter; i < value.length; i++) {
            if (value[i] == ' ') {
              sliceValue = i;
              break;
            }
          }
      }
      value = value.slice(0, sliceValue) + '...';
    }
    return value;
  }

}

