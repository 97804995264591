import { ContactService } from './../../shared/service/contact.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Contact } from '../../model/contact.model';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})

export class ContactComponent implements OnInit {

  focus: any;
  focus1: any;
  sent = false;
  check = false;
  contactForm: FormGroup = {} as FormGroup;
  contact: Contact = {} as Contact;
  firstNameCtrl: FormControl = {} as FormControl;
  lastNameCtrl: FormControl = {} as FormControl;
  emailCtrl: FormControl = {} as FormControl;
  subjectCtrl: FormControl = {} as FormControl;
  messageCtrl: FormControl = {} as FormControl;
// tslint:disable-next-line:no-inferrable-types
  subject: string = '';
  // tslint:disable-next-line:no-inferrable-types
  emailAddress: string = '';

  constructor(private fb: FormBuilder, private contactS: ContactService) {
    this.firstNameCtrl = fb.control('', [Validators.required]);
      this.lastNameCtrl = fb.control('', Validators.required);
      this.emailCtrl = fb.control('', [Validators.required, Validators.email]);
      this.messageCtrl = fb.control('', Validators.required);


      this.contactForm = fb.group({
        firstName: this.firstNameCtrl,
        lastName: this.lastNameCtrl,
        email: this.emailCtrl,
        message: this.messageCtrl
      });


  }

    ngOnInit(): void {
    }

    createContact() {
      this.contact = {
       firsname: this.contactForm.value.firstName,
       lasname: this.contactForm.value.lastName,
       email: this.contactForm.value.email,
       message: this.contactForm.value.message
     }
   }

    updatebox() {
      this.check = !this.check;
    }

    onSubmit() {

      if (this.contactForm.invalid || !this.check) {
          return;
      }
     this.createContact();
      this.contactS.sendMessage(this.contact);
      this.check = false;
      this.contactForm.reset();

    }

}
