import { Professionnal } from './../../model/professional.model';
import { Formation } from './../../model/formation.model';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { Skill } from 'app/model/skill.model';
import { BuildData } from 'app/shared/helper/buildData.helper';
import { TranslateService } from '@ngx-translate/core';
import { FileRead } from 'app/shared/helper/fileRead.helper';

@Component({
  selector: 'app-cv',
  templateUrl: './cv.component.html',
  styleUrls: ['./cv.component.css']
})
export class CvComponent implements OnInit {
  allSkill: Skill[] = [];
  allFormation: Formation[] = [];
  allProfessional: Professionnal[] = [];
  fr: FileRead = new FileRead();
  interets: string[] = ['interet_1', 'interet_2', 'interet_3', 'interet_4'];
  limite_1: number[] = new Array(32);
  limite_2: number[] = new Array(3);
  limite_3: number[] = new Array(55);
  phone : boolean = false;
  desktop : boolean = false;
  constructor(private renderer: Renderer2, private readonly buildata: BuildData,
    public translate: TranslateService) { }

  ngOnInit(): void {
    this.allSkill =  this.buildata.getSkillList();
    this.allFormation = this.buildata.getFormationList();
    this.allProfessional = this.buildata.getAllProfessional();
    //console.log(this.allProfessional.length);
    this.fr.readCv();

    this.renderer.listen('window', 'scroll', (event) => {
      const number = window.scrollY;
      if (window.innerWidth < 700 && !this.phone) {
        this.desktop = false;
        if (!this.phone) {this.phone = true};
      } else if(window.innerWidth > 700 && !this.desktop) {
        this.phone = false;
        if (!this.desktop) {this.desktop = true};  

        // Juste pour tester
      }
  });


  }


}


