import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Contact } from 'app/model/contact.model';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private http: HttpClient) { }

  public sendMessage(contact: Contact) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this.http.post( 'https://formspree.io/f/mwkawpgk',
    { name: contact.firsname + ' ' + contact.lasname , replyto: contact.email, message: contact.message },
    { 'headers': headers }).subscribe(
      response => {
        // console.log(response);
      }

    );
  }
}
