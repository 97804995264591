export class FileRead {
    private  file = '../../../assets/files/CV_BriceZokpato.pdf';

    readCv() {
        const reader = new FileReader();
       /*var resultat: any;
        reader.addEventListener('load', function(){
                this.resultat = reader.result;
        }, false);
        var bl = new Blob([this.file]);
        reader.readAsDataURL(bl);
        console.log(this.reader);*/
    }
}
